import React from "react";
import case1 from "../../../static/solutions/case1.jpg";
import laboratory2 from "../../../static/health/laboratory2.jpg";
import starOfLife3 from "../../../static/health/star-of-life3.png";
import helicopter from "../../../static/health/helicopter.jpg";

import Layout from "../../components/Layout/Layout";

const Health = () => {
  return (
    <Layout>
      <div className="page">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title">Healthcare</h1>
            <p className="section-description">
              We work with the Ministry of Health and all major medical
              institutions in the country on automating and optimizing the
              healthcare processes in order to offer more efficient and
              affordable healthcare services to the regular citizen.
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <img className="section-image" src={starOfLife3} alt="" />
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">
                Leading digital transformation in Healthcare
              </h1>
            </div>
          </div>
          <div className="row page__section --grid-1-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                S&T Moldova is a recognized and proven expert in healthcare
                technologies and is constantly employed by the the major
                institutions in the country to digitize and improve the critical
                healthcare processes.
              </p>
              <p className="section-description">
                Our services include consulting, automating processes,
                implementing new technologies and enhancing cross-sector
                collaboration in order to improve healthcare standards and
                consumer satisfaction.
              </p>
              <p className="section-description">
                We help turn the vision of a better, more efficient and more
                affordable healthcare system into reality by documenting,
                analyzing and offering solutions to streamlining healthcare
                operations and driving these into the XXI century through
                technology.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                className="section-description-image"
                src={laboratory2}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Cornerstone projects</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1 --align-left">
            <div className="tile-container">
              <div className="studies-tile height-50 --align-left ">
                <img className="section-image" src={case1} alt="" />
                <div className="studies-tile__content">
                  <a href="/" className="studies-title">
                    Digital Transformation
                  </a>
                  <p className="section-title-small --align-left ">
                    Hospital Management Information System (SIAAMS)
                  </p>
                  <p className="section-description --align-left ">
                    S&T Moldova implemented the primary information system used
                    by the Hospitals in Moldova (SIAAMS) and throught it we
                    constantly improve and optimize the critical processes
                    employed by the hospitals to deliver quality healthcare
                    services.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="studies-tile height-50 --align-left">
                <img className="section-image" src={helicopter} alt="" />
                <div className="studies-tile__content">
                  <a href="/" className="studies-title">
                    Digital Transformation
                  </a>
                  <p className="section-title-small --align-left ">
                    Emergency Medical Services Information System
                  </p>
                  <p className="section-description --align-left ">
                    S&T Moldova implemented the critical information system used
                    by Medical Emergency services to coordinate the core
                    emergency procedures and operations for more speed,
                    efficiency and quality in the critical moments.
                  </p>
                </div>
                {/* <a href="/" className="learn-more">
                  LEARN MORE
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Innovating Healthcare</h1>
            </div>
          </div>
          <div className="row page__section --grid-2-1 --align-left">
            <div>
              <p className="section-description">
                We have specialized teams that have been operating in the Health
                sector for decades accumulating knowledge and invaluable
                experience. Here are some ways we apply it.
              </p>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container ">
              <div
                className="accent-tile --align-left  height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Process optimization
                  </p>
                  <p className="section-description --align-left ">
                    We work closely with our clients to understand their
                    processes, identify problems, bottlenecks and drawbacks.
                    Then we apply intelligence and tech to optimize and
                    streamline them.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="accent-tile --align-left  height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Industry-leading software
                  </p>
                  <p className="section-description --align-left ">
                    We implement industry-leading pieces of software at critical
                    points in order to improve and modernize the back-office and
                    front-office operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="accent-tile --align-left  height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Enhancing collaboration
                  </p>
                  <p className="section-description --align-left ">
                    Employing the right tools and guidance for enhancing
                    internal and external collaboration in the joint effort of
                    providing great health services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div
                className="accent-tile --align-left   height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Medical data security
                  </p>
                  <p className="section-description --align-left ">
                    Security of medical data has always been a focal point in
                    all our technological decisions. Now, in the cloud cloud, it
                    has become ever more important and we are keeping up.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="accent-tile --align-left  height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Consumer experience
                  </p>
                  <p className="section-description --align-left ">
                    Ultimately technology must simplify the lives of the regular
                    citizens and provide accesibility and ease of use to all the
                    critical healthcare services. This is what we strive for
                    constantly.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="accent-tile --align-left  height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Staying ahead of the curve
                  </p>
                  <p className="section-description --align-left ">
                    Innovation never stops. We never stop. Our clients never
                    stop. Together we push boundaries of what is possible in
                    healthcare with newest solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row  page__section --grid-single --align-left">
          <div>
            <h1 className="section-sub-title">The Big Picture</h1>
          </div>
        </div>
        <div className="row page__section --grid-2-1 --align-left">
          <div>
            <p className="section-description">
              We have specialized teams that have been operating in the Health
              sector for decades accumulating knowledge and invaluable
              experience. Here are some ways we apply it.
            </p>
          </div>
        </div>
        <div className="row page__section --grid-1-1 --align-left">
          <div className="tile-container">
            <div className="info-tile  --align-left  height-25">
              <div className="icon-container">
                <i className="tile-icon icon icon-stethoscope " />
              </div>
              <p className="section-title-small --align-left ">
                Core digital transformation
              </p>
              <p className="section-description --align-left ">
                Transform all aspects of healthcare operations through digital
                innovation at all levels - software systems, newest hardware,
                cloud solutions and push towards a new, fresh mindset of agility
                and openness.
              </p>
            </div>
          </div>
          <div className="tile-container">
            <div className="info-tile  --align-left  height-25">
              <div className="icon-container">
                <i className="tile-icon icon icon-bank " />
              </div>
              <p className="section-title-small --align-left ">
                Redefining the rules for data in healthcare
              </p>
              <p className="section-description --align-left ">
                Using innovation and digitalisation we are redefining the rules
                of data collection, storage and processing within the healthcare
                sector to make it easily accessible and most importantly
                actionable for our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Health;
